import { createRouter, createWebHashHistory, RouteRecordRaw } from "vue-router";
import store from "@/store";
import { Mutations, Actions } from "@/store/enums/StoreEnums";
const routes: Array<RouteRecordRaw> = [

  {
    path: "/",
    redirect: "/sign-in",
    component: () => import("@/layout/Layout.vue"),
    children: [
      {
        path: "/dashboard",
        name: "dashboard",
        component: () => import("@/views/Dashboard.vue"),
      },
      {
        path: "/topup",
        name: "topup",
        component: () => import("@/views/topup/Topup.vue"),
      },
      {
        path: "/payment_method/:id",
        name: "payment_method",
        component: () => import("@/views/topup/paymentMethod.vue"),
      },
      {
        path: "/pay_va",
        name: "pay_va",
        component: () => import("@/views/topup/paymentVA.vue"),
      },
      {
        path: "/pay_cr",
        name: "pay_cr",
        component: () => import("@/views/topup/paymentCR.vue"),
      },
      {
        path: "/pay_tf",
        name: "pay_tf",
        component: () => import("@/views/topup/paymentTF.vue"),
      },
      {
        path: "/history_payment",
        name: "history_payment",
        component: () => import("@/views/topup/historyPayment.vue"),
      },
      {
        path: "/notification",
        name: "notification",
        component: () => import("@/views/notif/notification.vue"),
      },
      {
        path: "/detail_notification/:id",
        name: "detail_notification",
        component: () => import("@/views/notif/detailNotification.vue"),
      },
      {
        path: "/report-console",
        name: "report_console_index",
        component: () => import("@/views/report/report_console/index.vue"),
      },
      {
        path: "/report-detail/:id",
        name: "report_console_detail",
        component: () => import("@/views/report/report_console/detail.vue"),
      },
      {
        path: "/report-pl",
        name: "report_pl_index",
        component: () => import("@/views/report/report_pl/index.vue"),
      },
      {
        path: "/report-pl-detail/:id",
        name: "report_pl_detail",
        component: () => import("@/views/report/report_pl/detail.vue"),
      },
      {
        path: "/report/cash_flow",
        name: "cash_flow_index",
        component: () => import("@/views/report/cash_flow/index.vue"),
      },
      {
        path: "/report/cash_flow/detail/:id",
        name: "cash_flow_detail",
        component: () => import("@/views/report/cash_flow/detail.vue"),
      },
      {
        path: "/master/branch",
        name: "branch",
        component: () => import("@/views/master/branch/Branch.vue"),
      },
      {
        path: "/master/customer",
        name: "customer",
        component: () => import("@/views/master/customer/Customer.vue"),
      },
      {
        path: "/master/customer_branch/:id",
        name: "customer_branch",
        component: () => import("@/views/master/customer/Customer_branch.vue"),
      },
      {
        path: "/branch/detail_branch/:id",
        name: "detail_branch",
        component: () => import("@/views/master/branch/detail_branch.vue"),
      },
      {
        path: "/master/autonumber",
        name: "autonumber",
        component: () => import("@/views/master/autonumber/index.vue"),
      },
      {
        path: "/master/autonumber/detail/:id",
        name: "detailAutinumb",
        component: () => import("@/views/master/autonumber/detailAutonumb.vue"),
      },
      {
        path: "/master/users",
        name: "master_user",
        component: () => import("@/views/master/users/index.vue"),
      },
      {
        path: "/master/new_grade",
        name: "newGrade",
        component: () => import("@/views/master/users/newGrade.vue"),
      },
      {
        path: "/master/edit_grade/:id",
        name: "editGrade",
        component: () => import("@/views/master/users/editGrade.vue"),
      },
      {
        path: "/master/users/get_access",
        name: "get_access",
        component: () => import("@/views/master/users/getAccess.vue"),
      },
      {
        path: "/master/bot",
        name: "bot",
        component: () => import("@/views/master/bot/index.vue"),
      },
      {
        path: "/master/bot/get_access",
        name: "bot_access",
        component: () => import("@/views/master/bot/get_access.vue"),
      },
      {
        path: "/master/item",
        name: "item",
        component: () => import("@/views/master/item/index.vue"),
      },
      {
        path: "/master/item/detail/:id",
        name: "itemDetail",
        component: () => import("@/views/master/item/ItemDetail.vue"),
      },
      {
        path: "/master/vendor",
        name: "vendor",
        component: () => import("@/views/master/vendor/Vendor.vue"),
      },
      {
        path: "/master/vendor/:id",
        name: "vendor_",
        component: () => import("@/views/master/vendor/Vendor_.vue"),
      },
      {
        path: "/master/bank",
        name: "master_bank",
        component: () => import("@/views/master/bank/index.vue"),
      },
      {
        path: "/master/bank/detail/:id",
        name: "master_bank_detail",
        component: () => import("@/views/master/bank/detail.vue"),
      },
      {
        path: "/master/chart_of_account",
        name: "coa",
        component: () => import("@/views/master/chart_of_account/index.vue"),
      },
      {
        path: "/master/chart_of_account/detail",
        name: "coa_detail",
        component: () => import("@/views/master/chart_of_account/Detail.vue"),
        children: [
          {
            path: "",
            name: "coa_detail_mapped",
            component: () => import("@/views/master/chart_of_account/component/mapped.vue"),
          },
          {
            path: "invalid",
            name: "coa_detail_invalid",
            component: () => import("@/views/master/chart_of_account/component/invalid.vue"),
          },
        ]
      },
      {
        path: "/sales-invoice",
        name: "sales_invoice_index",
        component: () => import("@/views/sales/Invoice.vue"),
      },
      {
        path: "/sales/invoice/detail/:id",
        name: "invoice_detail",
        component: () => import("@/views/sales/InvoiceDetail.vue"),
      },
      {
        path: "/sales/invoice_to/detail/:id",
        name: "invoice_detail_to",
        component: () => import("@/views/sales/InvoiceDetail.vue"),
      },
      {
        path: "/sales/add_manual",
        name: "add_manual",
        component: () => import("@/views/sales/manual/addManual.vue"),
        children: [
          {
            path: "step-one",
            name: "add_manual_step_one",
            component: () => import("@/views/sales/manual/component/stepOne.vue"),
          },
          {
            path: "step-two",
            name: "add_manual_step_two",
            component: () => import("@/views/sales/manual/component/stepTwo.vue"),
          },
        ]
      },
      {
        path: "/sales/select_invoice/:id",
        name: "getCustomer",
        component: () => import("@/views/sales/manual/getCustomer.vue"),
      },
      {
        path: "/sales/automatic_detail",
        name: "automatic_detail",
        component: () => import("@/views/sales/automatic/detailAutomatic.vue"),
        children: [
          {
            path: 'process',
            name: 'sales_automatic_detail_process',
            component: () => import('@/views/sales/component/AutomaticProcess.vue')
          },
          {
            path: 'history',
            name: 'sales_automatic_detail_history',
            component: () => import('@/views/sales/component/AutomaticHistory.vue')
          },
        ]
      },
      {
        path: "/sales-invoice/manual/detail",
        redirect: '/sales-invoice',
        name: "sales_invoice_detail",
        component: () => import("@/views/sales/manual/detailSalesOrder.vue"),
        children: [
          {
            path: "proccess",
            name: "sales_invoice_manual_detail_proccess",
            component: () => import("@/views/sales/manual/component/proccess.vue"),
          },
          {
            path: "history",
            name: "sales_invoice_manual_detail_history",
            component: () => import("@/views/sales/manual/component/history.vue"),
          },
        ]
      },
      {
        path: "/sales/add_automatic",
        name: "addAutomatic",
        component: () => import("@/views/sales/automatic/Index.vue"),
      },
      {
        path: "/sales-receipt",
        name: "sales_receipt_index",
        component: () => import("@/views/sales/receipt/index.vue"),
      },
      {
        path: "/sales/receipt/detail",
        name: "receipt_detail",
        component: () => import("@/views/sales/receipt/detail/index.vue"),
        children: [
          {
            path: "proccess",
            name: "receipt_detail_proccess",
            component: () => import("@/views/sales/receipt/detail/proccessReceipt.vue"),
          },
          {
            path: "history",
            name: "receipt_detail_history",
            component: () => import("@/views/sales/receipt/detail/HistoryReceipt.vue"),
          },
        ]
      },
      {
        path: "/sales-receipt/detail_from_db",
        name: "receipt_detail_from_db",
        component: () => import("@/views/sales/receipt/detail/index2.vue"),
        children: [
          {
            path: "process",
            name: "sales_receipt_detail_process",
            component: () => import("@/views/sales/receipt/detail/ProcessReceiptFromDb.vue"),
          },
          {
            path: "history",
            name: "sales_receipt_detail_history",
            component: () => import("@/views/sales/receipt/detail/HistoryReceiptFromDb.vue"),
          },
        ]
      },
      {
        path: "/sales/receipt/detail_from_db/automatic",
        name: "receipt_detail_automatic",
        component: () => import("@/views/sales/receipt/importFromDbAutomatic/detailAutomatic.vue"),
        children: [
          {
            path: "proccess",
            name: "receipt_detail_automatic_proccess",
            component: () => import("@/views/sales/receipt/importFromDbAutomatic/component/automaticProccess.vue"),
          },
          {
            path: "history",
            name: "receipt_detail_automatic_history",
            component: () => import("@/views/sales/receipt/importFromDbAutomatic/component/automaticHistory.vue"),
          },
        ]
      },
      {
        path: "/sales/receipt/select_order",
        name: "select_order",
        component: () => import("@/views/sales/receipt/SelectTableOrder.vue"),
      },
      {
        path: "/sales/receipt/select_invoice",
        name: "select_invoice",
        component: () => import("@/views/sales/receipt/selectInvoice.vue"),
      },
      {
        path: "/sales/summary_invoice",
        name: "summary_invoice",
        component: () => import("@/views/sales/receipt/summaryInvoice.vue"),
      },
      {
        path: "/sales/receipt/import_from_db",
        name: "salesReceiptFromDb",
        component: () => import("@/views/sales/receipt/importFromDb/import.vue"),
      },
      {
        path: "/sales/receipt/import_from_db/automatic",
        name: "salesReceiptFromDbAutomatic",
        component: () => import("@/views/sales/receipt/importFromDbAutomatic/index.vue"),
      },
      {
        path: "/sales/receipt/select_receipt/:id",
        name: "selectSalesReceipt",
        component: () => import("@/views/sales/receipt/importFromDb/SelectSalesReceipt.vue"),
      },
      {
        path: "/sales/receipt/detail_fields/:id",
        name: "receipt_detail_field",
        component: () => import("@/views/sales/receipt/detail/ReceiptFieldsDetail.vue"),
      },
      {
        path: "/sales/receipt_to/detail_fields/:id",
        name: "receipt_detail_field_to",
        component: () => import("@/views/sales/receipt/detail/ReceiptFieldsDetail.vue"),
      },
      {
        path: "/journal-voucher",
        name: "general_ladger",
        component: () => import("@/views/general_ladger/journal_voucher/index.vue"),
      },
      {
        path: "/general_ladger/journal/detail/:id",
        name: "journal_detail_item",
        component: () => import("@/views/general_ladger/journal_voucher/manual/detail_journal.vue"),
      },
      {
        path: "/journal-voucher/detail_to/:id",
        name: "journal_detail_to",
        component: () => import("@/views/general_ladger/journal_voucher/manual/detail_journal.vue"),
      },
      {
        path: "/general_ladger/create",
        name: "journal_create",
        component: () => import("@/views/general_ladger/journal_voucher/manual/createJournal.vue"),
      },
      {
        path: "/general_ladger/from_xml",
        name: "journal_create_xml",
        component: () => import("@/views/general_ladger/journal_voucher/from_xml/index.vue"),
        children: [
          {
            path: "step_one/:id",
            name: "select_xml",
            component: () => import("@/views/general_ladger/journal_voucher/from_xml/component/step1.vue"),
          },
          {
            path: "replace_branch/:id",
            name: "replace_branch",
            component: () => import("@/views/general_ladger/journal_voucher/from_xml/component/replaceBranch.vue"),
          },
          {
            path: "export/:id",
            name: "export_xml",
            component: () => import("@/views/general_ladger/journal_voucher/from_xml/component/export.vue"),
          },
        ]
      },
      {
        path: "/general_ladger/summary_journal/:id",
        name: "summary_journal",
        component: () => import("@/views/general_ladger/journal_voucher/manual/summary_journal.vue"),
      },
      {
        path: "/journal-voucher/detail",
        name: "journal_detail",
        component: () => import("@/views/general_ladger/journal_voucher/manual/detail.vue"),
        children: [
          {
            path: "proccess",
            name: "journal_voucher_detail_proccess",
            component: () => import("@/views/general_ladger/component/journalProccess.vue")
          },
          {
            path: "history",
            name: "journal_voucher_detail_history",
            component: () => import("@/views/general_ladger/component/journalHistory.vue")
          },
        ]
      },
      {
        path: "/general_ladger/automatic/create",
        name: "hournal_automatic_create",
        component: () => import("@/views/general_ladger/journal_voucher/automatic/create.vue"),
      },
      {
        path: "/general_ladger/automatic/detail",
        redirect: '/journal-voucher',
        name: "journal_automatic_detail",
        component: () => import("@/views/general_ladger/journal_voucher/automatic/detail.vue"),
        children: [
          {
            path: "proccess",
            name: "journal_automatic_detail_proccess",
            component: () => import("@/views/general_ladger/journal_voucher/automatic/component/automaticProccess.vue"),
          },
          {
            path: "history",
            name: "journal_automatic_detail_history",
            component: () => import("@/views/general_ladger/journal_voucher/automatic/component/automaticHistory.vue"),
          },
        ]
      },
      {
        path: "/other-payment",
        name: "other_payment",
        component: () => import("@/views/cash&bank/other_payment/index.vue"),
      },
      {
        path: "/cash_and_bank/other_payment/add",
        name: "other_payment_add",
        component: () => import("@/views/cash&bank/other_payment/manual/addManual.vue"),
      },
      {
        path: "/other_payment/automatic/create",
        name: "other_payment_automatic_create",
        component: () => import("@/views/cash&bank/other_payment/automatic/create.vue"),
      },
      {
        path: "/other_payment/automatic/detail",
        name: "other_payment_automatic_detail",
        component: () => import("@/views/cash&bank/other_payment/automatic/detail.vue"),
        children: [
          {
            path: "proccess",
            name: "other_payment_automatic_detail_proccess",
            component: () => import("@/views/cash&bank/other_payment/automatic/component/automaticProccess.vue"),
          },
          {
            path: "history",
            name: "other_payment_automatic_detail_history",
            component: () => import("@/views/cash&bank/other_payment/automatic/component/automaticHistory.vue"),
          },
        ]
      },
      {
        path: "/cash_and_bank/other_payment/summary/:id",
        name: "other_payment_summary",
        component: () => import("@/views/cash&bank/other_payment/manual/SummaryOtherPayment.vue"),
      },
      {
        path: "/other-payment/detail",
        name: "other_payment_detail",
        component: () => import("@/views/cash&bank/other_payment/manual/OtherDetail.vue"),
        children: [
          {
            path: "proccess",
            name: "other_payment_detail_proccess",
            component: () => import("@/views/cash&bank/other_payment/manual/component/dataProccess.vue"),
          },
          {
            path: "history",
            name: "other_payment_detail_history",
            component: () => import("@/views/cash&bank/other_payment/manual/component/dataHistory.vue"),
          },
        ]
      },
      {
        path: "/cash_and_bank/other_payment/detail_data/:id",
        name: "other_payment_detail_data",
        component: () => import("@/views/cash&bank/other_payment/manual/DetailDataOther.vue"),
      },
      {
        path: "/cash_and_bank/other_payment/detail_to/:id",
        name: "other_payment_detail_to",
        component: () => import("@/views/cash&bank/other_payment/manual/DetailDataOther.vue"),
      },
      {
        path: "/other-deposit",
        name: "other_deposit",
        component: () => import("@/views/cash&bank/other_deposit/index.vue"),
      },
      {
        path: "/other-deposit/add",
        name: "other_deposit_add",
        component: () => import("@/views/cash&bank/other_deposit/manual/addManual.vue"),
      },
      {
        path: "/other_deposit/automatic/create",
        name: "other_deposit_automatic_create",
        component: () => import("@/views/cash&bank/other_deposit/automatic/create.vue"),
      },
      {
        path: "/other_deposit/automatic/detail",
        name: "other_deposit_automatic_detail",
        component: () => import("@/views/cash&bank/other_deposit/automatic/detail.vue"),
        children: [
          {
            path: "proccess",
            name: "other_deposit_automatic_detail_proccess",
            component: () => import("@/views/cash&bank/other_deposit/automatic/component/automaticProccess.vue"),
          },
          {
            path: "history",
            name: "other_deposit_automatic_detail_history",
            component: () => import("@/views/cash&bank/other_deposit/automatic/component/automaticHistory.vue"),
          },
        ]
      },
      {
        path: "/cash_and_bank/other_deposit/summary/:id",
        name: "other_deposit_summary",
        component: () => import("@/views/cash&bank/other_deposit/manual/SummaryOtherPayment.vue"),
      },
      {
        path: "/other-deposit/detail",
        name: "other_deposit_detail",
        component: () => import("@/views/cash&bank/other_deposit/manual/OtherDetail.vue"),
        children: [
          {
            path: "proccess",
            name: "other_deposit_detail_proccess",
            component: () => import("@/views/cash&bank/other_deposit/manual/component/dataProccess.vue"),
          },
          {
            path: "history",
            name: "other_deposit_detail_history",
            component: () => import("@/views/cash&bank/other_deposit/manual/component/dataHistory.vue"),
          },
        ]
      },
      {
        path: "/cash_and_bank/other_deposit/detail_data/:id",
        name: "other_deposit_detail_data",
        component: () => import("@/views/cash&bank/other_deposit/manual/DetailDataOther.vue"),
      },
      {
        path: "/cash_and_bank/other_deposit/detail_to/:id",
        name: "other_deposit_detail_to",
        component: () => import("@/views/cash&bank/other_deposit/manual/DetailDataOther.vue"),
      },
      {
        path: "/compare",
        name: "compare_index",
        component: () => import("@/views/Compare/index.vue"),
      },
      {
        path: "/compare/create",
        name: "compare_create",
        component: () => import("@/views/Compare/addTransaction.vue"),
      },
      {
        path: "/compare/detail/:id",
        name: "compare_detail",
        component: () => import("@/views/Compare/detail_compare/index.vue"),
      },
      {
        path: "/compare/edit/:id",
        name: "compare_edit",
        component: () => import("@/views/Compare/edit_compare/index.vue"),
      },
      {
        path: "/compare/sales_receipt/edit/:id",
        name: "compare_receipt_edit",
        component: () => import("@/views/compare_sales_receipt/edit_compare/index.vue"),
      },
      {
        path: "/compare/purchase-invoice",
        name: "compare_purchase_invoice",
        component: () => import("@/views/ComparePi/index.vue"),
      },
      {
        path: "/compare/purchase-invoice/detail/:id",
        name: "compare_purchase_invoice_detail",
        component: () => import("@/views/ComparePi/detail_compare/index.vue"),
      },
      {
        path: "/compare/purchase-invoice/edit/:id",
        name: "compare_purchase_invoice_edit",
        component: () => import("@/views/ComparePi/edit_compare/index.vue"),
      },
      {
        path: "/compare/purchase-payment/edit/:id",
        name: "compare_purchase_payment_edit",
        component: () => import("@/views/ComparePp/edit_compare/index.vue"),
      },
      {
        path: "/compare/purchase-payment/detail/:id",
        name: "compare_purchase_payment_detail",
        component: () => import("@/views/ComparePp/detail_compare/index.vue"),
      },
      {
        path: "/compare/journal_voucher/edit/:id",
        name: "compare_journal_voucher",
        component: () => import("@/views/compare_journal_voucher/edit.vue"),
      },
      {
        path: "/profile",
        name: "profileku",
        component: () => import("@/views/profile/myProfile.vue"),
      },
      {
        path: "/security",
        name: "security",
        component: () => import("@/views/profile/Security.vue"),
      },
      {
        path: "/payment",
        name: "payment",
        component: () => import("@/views/profile/Payment.vue"),
      },
      {
        path: "/settings",
        name: "settings",
        component: () => import("@/views/settings/index.vue"),
      },
      {
        path: "/settings/company_setting",
        name: "settingCompany",
        component: () => import("@/views/settings/companySetting.vue"),
      },
      // PURCHASE INVOICE:
      {
        path: "/purchase/invoice",
        name: "purchaseInvoice",
        component: () => import("@/views/purchase/invoice/index.vue"),
      },
      {
        path: "/purchase/invoice/add_manual",
        name: "purchaseInvoiceAddManual",
        component: () =>
          import("@/views/purchase/invoice/manual/addManual.vue"),
        children: [
          {
            path: "step-one",
            name: "purchase_invoice_step_one",
            component: () => import("@/views/purchase/invoice/manual/formStep/Step1.vue"),
          },
          {
            path: "step-two",
            name: "purchase_invoice_step_two",
            component: () => import("@/views/purchase/invoice/manual/formStep/Step2.vue"),
          },
        ]
      },
      {
        path: "/purchase/invoice/select_invoice/:id",
        name: "purchaseInvoiceSelectInvoice",
        component: () =>
          import("@/views/purchase/invoice/manual/selectInvoice.vue"),
      },
      {
        path: "/purchase/invoice/manual_detail",
        name: "purchaseInvoiceManualDetail",
        component: () => import("@/views/purchase/invoice/manual/groupDetails.vue"),
        children: [
          {
            path: "proccess",
            name: "purchase_invoice_detail_process",
            component: () => import("@/views/purchase/invoice/manual/components/processDetails.vue")
          },
          {
            path: "history",
            name: "purchase_invoice_detail_history",
            component: () => import("@/views/purchase/invoice/manual/components/historyDetails.vue")
          },
        ]
      },
      {
        path: "/purchase/invoice/details/:type/:id",
        name: "purchaseInvoiceDetails",
        component: () => import("@/views/purchase/invoice/invoiceDetails.vue"),
      },
      {
        path: "/purchase/invoice/add_automatic",
        name: "purchaseInvoiceAddAutomatic",
        component: () => import("@/views/purchase/invoice/automatic/Index.vue"),
      },
      {
        path: "/purchase/invoice/automatic_detail/:id",
        name: "purchaseInvoiceAutomaticDetails",
        component: () =>
          import("@/views/purchase/invoice/automatic/detailAutomatic.vue"),
      },
      // PURCHASE ORDER:
      {
        path: "/purchase/order",
        name: "purchaseOrder",
        component: () => import("@/views/purchase/order/index.vue"),
      },
      {
        path: "/purchase/order/add_manual",
        name: "purchaseOrderAddManual",
        component: () => import("@/views/purchase/order/manual/addManual.vue"),
        children: [
          {
            path: "step-one",
            name: "purchase_order_step_one",
            component: () => import("@/views/purchase/order/manual/formStep/Step1.vue"),
          },
          {
            path: "step-two",
            name: "purchase_order_step_two",
            component: () => import("@/views/purchase/order/manual/formStep/Step2.vue"),
          },
        ]
      },
      {
        path: "/purchase/order/select_order/:id",
        name: "purchaseOrderSelectOrder",
        component: () =>
          import("@/views/purchase/order/manual/selectOrder.vue"),
      },
      {
        path: "/purchase/order/manual_detail",
        name: "purchaseOrderManualDetail",
        component: () => import("@/views/purchase/order/manual/groupDetails.vue"),
        children: [
          {
            path: "proccess",
            name: "purchase_order_detail_process",
            component: () => import("@/views/purchase/order/manual/components/processDetails.vue")
          },
          {
            path: "history",
            name: "purchase_order_detail_history",
            component: () => import("@/views/purchase/order/manual/components/historyDetails.vue")
          },
        ]
      },
      {
        path: "/purchase/order/details/:type/:id",
        name: "purchaseOrderDetails",
        component: () => import("@/views/purchase/order/orderDetails.vue"),
      },
      {
        path: "/purchase/order/add_automatic",
        name: "purchaseOrderAddAutomatic",
        component: () => import("@/views/purchase/order/automatic/Index.vue"),
      },
      {
        path: "/purchase/order/automatic_detail/:id",
        name: "purchaseOrderAutomaticDetails",
        component: () =>
          import("@/views/purchase/order/automatic/detailAutomatic.vue"),
      },
      // RECEIVE ITEM
      {
        path: "/receive/item",
        name: "receiveItem",
        component: () => import("@/views/purchase/receive_item/index.vue"),
      },
      {
        path: "/receive/item/add_manual",
        name: "receiveItemAddManual",
        component: () =>
          import("@/views/purchase/receive_item/manual/addManual.vue"),
        children: [
          {
            path: "step-one",
            name: "receive_item_step_one",
            component: () => import("@/views/purchase/receive_item/manual/formStep/Step1.vue"),
          },
          {
            path: "step-two",
            name: "receive_item_step_two",
            component: () => import("@/views/purchase/receive_item/manual/formStep/Step2.vue"),
          },
        ]
      },
      {
        path: "/receive/item/select_order/:id",
        name: "receiveItemSelectOrder",
        component: () =>
          import("@/views/purchase/receive_item/manual/selectReceive.vue"),
      },
      {
        path: "/receive/item/manual_detail",
        name: "receiveItemManualDetail",
        component: () => import("@/views/purchase/receive_item/manual/groupDetails.vue"),
        children: [
          {
            path: "proccess",
            name: "receive_item_detail_process",
            component: () => import("@/views/purchase/receive_item/manual/components/processDetails.vue")
          },
          {
            path: "history",
            name: "receive_item_detail_history",
            component: () => import("@/views/purchase/receive_item/manual/components/historyDetails.vue")
          },
        ]
      },
      {
        path: "/receive/item/details/:type/:id",
        name: "receiveItemDetails",
        component: () => import("@/views/purchase/receive_item/receiveDetails.vue"),
      },
      {
        path: "/receive/item/add_automatic",
        name: "receiveItemAddAutomatic",
        component: () => import("@/views/purchase/receive_item/automatic/Index.vue"),
      },
      {
        path: "/receive/item/automatic_detail",
        name: "receiveItemAutomaticDetails",
        component: () =>
          import("@/views/purchase/receive_item/automatic/detailAutomatic.vue"),
        children: [
          {
            path: "proccess",
            name: "receiveItemAutomaticDetailsProccess",
            component: () => import("@/views/purchase/receive_item/automatic/component/detailProccess.vue"),
          },
          {
            path: "history",
            name: "receiveItemAutomaticDetailsHistory",
            component: () => import("@/views/purchase/receive_item/automatic/component/detailHistory.vue"),
          },
        ]
      },
      // PROJECT
      {
        path: "/project",
        name: "Project",
        component: () => import("@/views/company/project/index.vue"),
      },
      {
        path: "/project/add_manual",
        name: "projectAddManual",
        component: () =>
          import("@/views/company/project/manual/addManual.vue"),
        children: [
          {
            path: "step-one",
            name: "project_step_one",
            component: () => import("@/views/company/project/manual/formStep/Step1.vue"),
          },
          {
            path: "step-two",
            name: "project_step_two",
            component: () => import("@/views/company/project/manual/formStep/Step2.vue"),
          },
        ]
      },
      {
        path: "/project/automatic/add",
        name: "projectAutomaticAdd",
        component: () => import("@/views/company/project/automatic/Index.vue"),
      },
      {
        path: "/project/select_order/:id",
        name: "projectSelectOrder",
        component: () =>
          import("@/views/company/project/manual/selectProject.vue"),
      },
      {
        path: "/project/manual_detail",
        name: "projectManualDetail",
        component: () => import("@/views/company/project/manual/groupDetails.vue"),
        children: [
          {
            path: "proccess",
            name: "project_detail_process",
            component: () => import("@/views/company/project/manual/components/processDetails.vue")
          },
          {
            path: "history",
            name: "project_detail_history",
            component: () => import("@/views/company/project/manual/components/historyDetails.vue")
          },
        ]
      },
      {
        path: "/project/details/:type/:id",
        name: "projectDetails",
        component: () => import("@/views/company/project/projectDetails.vue"),
      },
      {
        path: "/project/automatic_detail",
        name: "projectAutomaticDetails",
        component: () => import("@/views/company/project/automatic/detailAutomatic.vue"),
        children: [
          {
            path: "proccess",
            name: "projectAutomaticDetailsProccess",
            component: () => import("@/views/company/project/automatic/component/detailProccess.vue"),
          },
          {
            path: "history",
            name: "projectAutomaticDetailsHistory",
            component: () => import("@/views/company/project/automatic/component/detailHistory.vue"),
          },
        ]
      },
      // ITEM TRANSFER
      {
        path: "/item/transfer",
        name: "itemTransfer",
        component: () => import("@/views/inventory/item_transfer/index.vue"),
      },
      {
        path: "/item/transfer/add_manual",
        name: "item_transfer_add_manual",
        component: () =>
          import("@/views/inventory/item_transfer/manual/addManual.vue"),
        children: [
          {
            path: "step-one",
            name: "item_transfer_item_step_one",
            component: () => import("@/views/inventory/item_transfer/manual/formStep/Step1.vue"),
          },
          {
            path: "step-two",
            name: "item_transfer_item_step_two",
            component: () => import("@/views/inventory/item_transfer/manual/formStep/Step2.vue"),
          },
        ]
      },
      {
        path: "/item/transfer/select_order/:id",
        name: "itemTransferSelectOrder",
        component: () =>
          import("@/views/inventory/item_transfer/manual/selectItemTransfer.vue"),
      },
      {
        path: "/item/transfer/manual_detail",
        name: "item_transfer_manual_detail",
        component: () => import("@/views/inventory/item_transfer/manual/groupDetails.vue"),
        children: [
          {
            path: "proccess",
            name: "item_transfer_detail_process",
            component: () => import("@/views/inventory/item_transfer/manual/components/processDetails.vue")
          },
          {
            path: "history",
            name: "item_transfer_detail_history",
            component: () => import("@/views/inventory/item_transfer/manual/components/historyDetails.vue")
          },
        ]
      },
      {
        path: "/item/transfer/details/:type/:id",
        name: "itemTransferItemDetails",
        component: () => import("@/views/inventory/item_transfer/itemTransferDetails.vue"),
      },
      {
        path: "/item/transfer/add_automatic",
        name: "itemTransferAddAutomatic",
        component: () => import("@/views/inventory/item_transfer/automatic/Index.vue"),
      },
      {
        path: "/item/transfer/automatic_detail/:id",
        name: "itemTransferAutomaticDetails",
        component: () =>
          import("@/views/inventory/item_transfer/automatic/detailAutomatic.vue"),
      },
      // JOB COSTING
      {
        path: "/job-costing",
        name: "jobCosting",
        component: () => import("@/views/inventory/job_costing/index.vue"),
      },
      {
        path: "/job-costing/add_manual",
        name: "job_costing_add_manual",
        component: () =>
          import("@/views/inventory/job_costing/manual/addManual.vue"),
        children: [
          {
            path: "step-one",
            name: "job_costing_step_one",
            component: () => import("@/views/inventory/job_costing/manual/formStep/Step1.vue"),
          },
          {
            path: "step-two",
            name: "job_costing_step_two",
            component: () => import("@/views/inventory/job_costing/manual/formStep/Step2.vue"),
          },
        ]
      },
      {
        path: "/job-costing/select_order/:id",
        name: "jobCostingSelectOrder",
        component: () =>
          import("@/views/inventory/job_costing/manual/selectJobCosting.vue"),
      },
      {
        path: "/job-costing/manual_detail",
        name: "job_costing_manual_detail",
        component: () => import("@/views/inventory/job_costing/manual/groupDetails.vue"),
        children: [
          {
            path: "proccess",
            name: "job_costing_detail_process",
            component: () => import("@/views/inventory/job_costing/manual/components/processDetails.vue")
          },
          {
            path: "history",
            name: "job_costing_detail_history",
            component: () => import("@/views/inventory/job_costing/manual/components/historyDetails.vue")
          },
        ]
      },
      {
        path: "/job-costing/details/:type/:id",
        name: "jobCostingDetails",
        component: () => import("@/views/inventory/job_costing/jobCostingDetails.vue"),
      },
      {
        path: "/job-costing/add_automatic",
        name: "jobCostingAddAutomatic",
        component: () => import("@/views/inventory/job_costing/automatic/Index.vue"),
      },
      {
        path: "/job-costing/automatic_detail/:id",
        name: "jobCostingAutomaticDetails",
        component: () =>
          import("@/views/inventory/job_costing/automatic/detailAutomatic.vue"),
      },
      // PURCHASE PAYMENT:
      {
        path: "/purchase/payment",
        name: "purchasePayment",
        component: () => import("@/views/purchase/payment/index.vue"),
      },
      {
        path: "/purchase/payment/import_from_db",
        name: "purchasePaymentFromDb",
        component: () =>
          import("@/views/purchase/payment/manual_from_db/addManual.vue"),
        children: [
          {
            path: "step-one",
            name: "purchase_payment_step_one",
            component: () => import("@/views/purchase/payment/manual_from_db/formStep/step1.vue"),
          },
          {
            path: "step-two",
            name: "purchase_payment_step_two",
            component: () => import("@/views/purchase/payment/manual_from_db/formStep/step2.vue"),
          },
        ]
      },
      {
        path: "/purchase/payment/import_from_db/detail",
        name: "purchasePaymentFromDbDetail",
        component: () =>
          import("@/views/purchase/payment/manual_from_db/detailGroup.vue"),
        children: [
          {
            path: "proccess",
            name: "purchase_payment_detail_process",
            component: () => import("@/views/purchase/payment/manual_from_db/component/DataProcess.vue")
          },
          {
            path: "history",
            name: "purchase_payment_detail_history",
            component: () => import("@/views/purchase/payment/manual_from_db/component/DataHistory.vue")
          },
        ]
      },
      {
        path: "/purchase/payment/import_from_db/detail_data/:id",
        name: "purchasePaymentFromDbDetailData",
        component: () =>
          import("@/views/purchase/payment/manual_from_db/detailData.vue"),
      },
      {
        path: "/purchase/payment/import_from_db/detail_data_to/:id",
        name: "purchasePaymentFromDbDetailToData",
        component: () =>
          import("@/views/purchase/payment/manual_from_db/detailData.vue"),
      },
      {
        path: "/purchase/payment/import_from_db/summary/:id",
        name: "purchase_payment_summary",
        component: () =>
          import("@/views/purchase/payment/manual_from_db/selectToExport.vue"),
      },
      {
        path: "/purchase/payment/import_from_db/automatic",
        name: "purchasePaymentFromDbAutomatic",
        component: () => import("@/views/purchase/payment/automatic_from_db/addAutomatic.vue"),
      },
      {
        path: "/purchase/payment/import_from_db/detail/automatic/:id",
        name: "purchasePaymentFromDbAutomaticDetail",
        component: () => import("@/views/purchase/payment/automatic_from_db/detailAutomatic.vue"),
      },
      // PURCHASE REQUISITION
      {
        path: "/purchase-requisition",
        name: "purchase_requisition_index",
        component: () => import("@/views/inventory/purchase_requisition/Index.vue"),
      },
      {
        path: "/purchase-requisition/summary/:id",
        name: "purchase_requisition_detail_summary",
        component: () => import("@/views/inventory/purchase_requisition/manual/SummaryPr.vue"),
      },
      {
        path: "/purchase-requisition/add-manual",
        redirect: '/purchase-requisition/add-manual/step-one',
        component: () => import("@/views/inventory/purchase_requisition/manual/form/AddManual.vue"),
        children: [
          {
            path: "step-one",
            name: "purchase_requisition_step_one",
            component: () => import("@/views/inventory/purchase_requisition/manual/form/Step1.vue"),
          },
          {
            path: "step-two",
            name: "purchase_requisition_step_two",
            component: () => import("@/views/inventory/purchase_requisition/manual/form/Step2.vue"),
          },
        ]
      },
      {
        path: "/purchase-requisition/detail",
        name: "purchase_requisition_detail",
        component: () => import("@/views/inventory/purchase_requisition/detailPr.vue"),
        children: [
          {
            path: "proccess",
            name: "purchase_requisition_detail_proccess",
            component: () => import("@/views/inventory/purchase_requisition/manual/component/DetailProccess.vue"),
          },
          {
            path: "history",
            name: "purchase_requisition_detail_history",
            component: () => import("@/views/inventory/purchase_requisition/manual/component/DetailHistory.vue"),
          },
        ]
      },
      {
        path: "/purchase-requisition/detail_data/:id",
        name: "purchase_requisition_detail_data",
        component: () => import("@/views/inventory/purchase_requisition/manual/component/DetailData.vue"),
      },


      // ITEM ADJUSTMENT
      {
        path: "/item-adjustment",
        name: "item_adjustment_index",
        component: () => import("@/views/inventory/item_adjustment/Index.vue"),
      },
      {
        path: "/item-adjustment/summary/:id",
        name: "item_adjustment_detail_summary",
        component: () => import("@/views/inventory/item_adjustment/manual/SummaryAdjustment.vue"),
      },
      {
        path: "/item-adjustment/add-manual",
        redirect: '/item-adjustment/add-manual/step-one',
        component: () => import("@/views/inventory/item_adjustment/manual/form/AddManual.vue"),
        children: [
          {
            path: "step-one",
            name: "item_adjustment_step_one",
            component: () => import("@/views/inventory/item_adjustment/manual/form/Step1.vue"),
          },
          {
            path: "step-two",
            name: "item_adjustment_step_two",
            component: () => import("@/views/inventory/item_adjustment/manual/form/Step2.vue"),
          },
        ]
      },
      {
        path: "/item-adjustment/automatic/add",
        name: 'item_adjustment_automatic_add',
        component: () => import("@/views/inventory/item_adjustment/automatic/create.vue"),
      },
      {
        path: "/item-adjustment/automatic/detail",
        name: 'item_adjustment_automatic_detail',
        component: () => import("@/views/inventory/item_adjustment/automatic/detail.vue"),
        children : [
          {
            path: "proccess",
            name: "item_adjustment_detail_automatic_proccess",
            component: () => import("@/views/inventory/item_adjustment/automatic/component/automaticProccess.vue"),
          },
          {
            path: "history",
            name: "item_adjustment_detail_automatic_history",
            component: () => import("@/views/inventory/item_adjustment/automatic/component/automaticHistory.vue"),
          },
        ]
      },
      {
        path: "/item-adjustment/detail",
        name: "item_adjustment_detail",
        component: () => import("@/views/inventory/item_adjustment/detailAdjustment.vue"),
        children: [
          {
            path: "proccess",
            name: "item_adjustment_detail_proccess",
            component: () => import("@/views/inventory/item_adjustment/manual/component/DetailProccess.vue"),
          },
          {
            path: "history",
            name: "item_adjustment_detail_history",
            component: () => import("@/views/inventory/item_adjustment/manual/component/DetailHistory.vue"),
          },
        ]
      },
      {
        path: "/item-adjustment/detail_data/:id",
        name: "item_adjustment_detail_data",
        component: () => import("@/views/inventory/item_adjustment/manual/component/DetailData.vue"),
      },


      {
        path: "/sales/down-payment",
        name: "salesDownPayment",
        component: () => import("@/views/sales/down_payment/index.vue"),
      },
      {
        path: "/sales/down-payment/import_from_db",
        name: "salesDownPaymentFromDb",
        component: () => import("@/views/sales/down_payment/manual_from_db/addManual.vue"),
      },
      {
        path: "/sales/down-payment/import_from_db/detail",
        name: "salesDownPaymentFromDbDetail",
        component: () => import("@/views/sales/down_payment/manual_from_db/detailGroup.vue"),
        children: [
          {
            path: "proccess",
            name: "salesDownPaymentFromDbDetailProccess",
            component: () => import("@/views/sales/down_payment/manual_from_db/component/DataProcess.vue"),
          },
          {
            path: "history",
            name: "salesDownPaymentFromDbDetailHistory",
            component: () => import("@/views/sales/down_payment/manual_from_db/component/DataHistory.vue"),
          },
        ]
      },
      {
        path: "/sales/down-payment/import_from_db/detail_data/:id",
        name: "salesDownPaymentFromDbDetailData",
        component: () => import("@/views/sales/down_payment/manual_from_db/detailData.vue"),
      },
      {
        path: "/sales/down-payment/import_from_db/detail_data_to/:id",
        name: "salesDownPaymentFromDbDetailToData",
        component: () => import("@/views/sales/down_payment/manual_from_db/detailData.vue"),
      },
      {
        path: "/sales/down-payment/import_from_db/summary/:id",
        name: "sales_down_payment_summary",
        component: () =>
          import("@/views/sales/down_payment/manual_from_db/selectToExport.vue"),
      },
      {
        path: "/sales/down-payment/automatic/create",
        name: "salesDownPaymentAutomatic",
        component: () => import("@/views/sales/down_payment/automatic/create.vue"),
      },
      {
        path: "/sales/down-payment/automatic/detail",
        name: "salesDownPaymentAutomaticDetail",
        component: () => import("@/views/sales/down_payment/automatic/detail_group.vue"),
        children: [
          {
            path: "proccess",
            name: "salesDownPaymentAutomaticDetailProccess",
            component: () => import("@/views/sales/down_payment/automatic/tab/automaticProccess.vue"),
          },
          {
            path: "history",
            name: "salesDownPaymentAutomaticDetailHistory",
            component: () => import("@/views/sales/down_payment/automatic/tab/automaticHistory.vue"),
          },
        ]
      },
      {
        path: "/sales-order",
        name: "sales_order_index",
        component: () => import("@/views/sales/order/Index.vue"),
      },
      {
        path: "/sales-order/detail",
        name: "sales_order_detail",
        component: () => import("@/views/sales/order/DetailSo.vue"),
        children: [
          {
            path: "proccess",
            name: "sales_order_detail_proccess",
            component: () => import("@/views/sales/order/manual/component/DetailProccess.vue"),
          },
          {
            path: "history",
            name: "sales_order_detail_history",
            component: () => import("@/views/sales/order/manual/component/DetailHistory.vue"),
          },
        ]
      },
      {
        path: "/sales-order/detail_data/:id",
        name: "sales_order_detail_data",
        component: () => import("@/views/sales/order/manual/component/DetailData.vue"),
      },
      {
        path: "/sales-order/summary/:id",
        name: "sales_order_detail_summary",
        component: () => import("@/views/sales/order/manual/SummarySo.vue"),
      },
      {
        path: "/sales-order/add-manual",
        redirect: '/sales-order/add-manual/step-one',
        component: () => import("@/views/sales/order/manual/form/AddManual.vue"),
        children: [
          {
            path: "step-one",
            name: "sales_order_step_one",
            component: () => import("@/views/sales/order/manual/form/Step1.vue"),
          },
          {
            path: "step-two",
            name: "sales_order_step_two",
            component: () => import("@/views/sales/order/manual/form/Step2.vue"),
          },
        ]
      },
      {
        path: "/sales-order/automatic/add",
        name: "sales_order_automatic",
        component: () => import("@/views/sales/order/automatic/create.vue"),
      },
      {
        path: "/sales-order/automatic/detail",
        name: "sales_order_automatic_detail",
        component: () => import("@/views/sales/order/automatic/detail.vue"),
        children: [
          {
            path: "proccess",
            name: "sales_order_automatic_detail_proccess",
            component: () => import("@/views/sales/order/automatic/component/automaticProccess.vue"),
          },
          {
            path: "history",
            name: "sales_order_automatic_detail_history",
            component: () => import("@/views/sales/order/automatic/component/automaticHistory.vue"),
          },
        ]
      },
      {
        path: "/delivery-order",
        name: "delivery_order_index",
        component: () => import("@/views/sales/delivery_order/Index.vue"),
      },
      {
        path: "/delivery-order/summary/:id",
        name: "delivery_order_detail_summary",
        component: () => import("@/views/sales/delivery_order/manual/SummaryDo.vue"),
      },
      {
        path: "/delivery-order/detail_data/:id",
        name: "delivery_order_detail_data",
        component: () => import("@/views/sales/delivery_order/manual/component/DetailData.vue"),
      },
      {
        path: "/delivery-order/detail",
        name: "delivery_order_detail",
        component: () => import("@/views/sales/delivery_order/DetailDo.vue"),
        children: [
          {
            path: "proccess",
            name: "delivery_order_detail_proccess",
            component: () => import("@/views/sales/delivery_order/manual/component/DetailProccess.vue"),
          },
          {
            path: "history",
            name: "delivery_order_detail_history",
            component: () => import("@/views/sales/delivery_order/manual/component/DetailHistory.vue"),
          },
        ]
      },
      {
        path: "/delivery-order/automatic/add",
        name: "deliveryOrderAutomaticAdd",
        component: () => import("@/views/sales/delivery_order/automatic/Index.vue"),
      },
      {
        path: "/delivery-order/add-manual",
        redirect: '/delivery-order/add-manual/step-one',
        component: () => import("@/views/sales/delivery_order/manual/form/AddManual.vue"),
        children: [
          {
            path: "step-one",
            name: "delivery_order_step_one",
            component: () => import("@/views/sales/delivery_order/manual/form/Step1.vue"),
          },
          {
            path: "step-two",
            name: "delivery_order_step_two",
            component: () => import("@/views/sales/delivery_order/manual/form/Step2.vue"),
          },
        ]
      },
    ],
  },
  {
    path: "/history_payment/detail_print",
    name: "detail_print",
    component: () => import("@/views/topup/pdfViewr.vue"),
  },
  {
    path: "/",
    component: () => import("@/components/page-layouts/Auth.vue"),
    children: [
      {
        path: "/auto_get_session",
        name: "session_auto",
        component: () => import("@/views/scheduler/get_session.vue"),
      },
      {
        path: "/schedule_invoice",
        name: "session_auto",
        component: () => import("@/views/scheduler/invoice.vue"),
      },
      {
        path: "/sales/db_proccess",
        name: "db_proccess",
        component: () => import("@/views/sales/manual/toProccess.vue"),
      },
      {
        path: "/auto_login",
        name: "auto_login",
        component: () => import("@/views/master/bot/form_view.vue"),
      },
      {
        path: "/sign-in",
        name: "sign-in",
        component: () =>
          import("@/views/crafted/authentication/basic-flow/SignIn.vue"),
      },
      {
        path: "/sign-up",
        name: "sign-up",
        component: () =>
          import("@/views/crafted/authentication/basic-flow/SignUp.vue"),
      },
      {
        path: "/password-reset",
        name: "password-reset",
        component: () =>
          import("@/views/crafted/authentication/basic-flow/PasswordReset.vue"),
      },
      {
        path: "/new-password",
        name: "new-password",
        component: () =>
          import("@/views/crafted/authentication/basic-flow/NewPassword.vue"),
      },
      {
        path: "/success-forgot-password",
        name: "success-forgot-password",
        component: () =>
          import(
            "@/views/crafted/authentication/basic-flow/SuccessForgotPassword.vue"
          ),
      },
    ],
  },

  {
    // the 404 route, when none of the above matches
    path: "/404",
    name: "404",
    component: () => import("@/views/crafted/authentication/Error404.vue"),
  },
  {
    path: "/500",
    name: "500",
    component: () => import("@/views/crafted/authentication/Error500.vue"),
  },
  {
    path: "/:pathMatch(.*)*",
    redirect: "/404",
  },
];

const router = createRouter({
  history: createWebHashHistory(),
  routes,
});

router.beforeEach(() => {
  // reset config to initial state
  store.commit(Mutations.RESET_LAYOUT_CONFIG);
  // store.state.global.loading = true
  // console.log(store)
  store.dispatch(Actions.VERIFY_AUTH);

  if (localStorage.getItem("refresh_token")) {
    store.dispatch(Actions.SET_ROLE);
  }
  // Scroll page to top on every route change
  setTimeout(() => {
    window.scrollTo(0, 0);
  }, 100);
});

export default router;
